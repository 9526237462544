import React from 'react';

import Layout from '../components/layout';
import Navigation from '../components/header/Navigation';
import { TermsOfServiceContent } from '../components/terms-of-service/TermsOfServiceContent';
import FooterWrapper from '../components/index/FooterWrapper';

const ForTermsOfServicePage = () => (
  <Layout>
    <Navigation />
    <TermsOfServiceContent />
    <FooterWrapper noLine={1} extraPadding={1} />
  </Layout>
);

export default ForTermsOfServicePage;
