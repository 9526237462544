import React from "react";
import styled from "@emotion/styled";
import { Row } from "antd/lib/index";
import { Link } from "gatsby";

import { mediaQueries, color } from "../variables";

const SectionWrapper = styled(Row)`
  padding: 10rem 12rem 6rem;
  background-color: ${color.white};
  background-size: cover;
  ${mediaQueries.xxxl} {
    padding: 10rem 9rem 1rem;
  }
  ${mediaQueries.xxl} {
    padding: 10rem 9rem 6rem;
  }
  ${mediaQueries.xl} {
    padding: 10rem 5rem 1rem;
  }
  ${mediaQueries.lg} {
    padding: 8rem 2rem;
  }
  ${mediaQueries.md} {
    padding: 8rem 2rem 2rem;
  }
  ${mediaQueries.sm} {
    padding: 7rem 1rem;
  }
`;

const CenteredHeader = styled.div`
  text-align: center;
`;

export const TermsOfServiceContent = props => (
  <SectionWrapper>
    <CenteredHeader>
      <h4>MODERN HEALTH</h4>
      <h4>TERMS OF SERVICE</h4>
      <p>Last Modified: December 23, 2019</p>
    </CenteredHeader>
    <p>
      IN THE EVENT OF A MEDICAL EMERGENCY, CALL 911. DO NOT DISREGARD OR DELAY
      SEEKING MEDICAL ADVICE BECAUSE OF CONTENT YOU HAVE READ ON THIS WEBSITE OR
      OBTAINED THROUGH OUR SERVICES. THIS WEBSITE DOES NOT CONTAIN MEDICAL
      ADVICE AND WE DO NOT MONITOR THIS WEBSITE OR COMMUNICATIONS FROM THIS
      WEBSITE FOR MEDICAL DIAGNOSTIC OR EMERGENCY HEALTH CARE PURPOSES.
    </p>
    <p>
      These Terms of Service govern your use of the websites, subdomains, or
      services owned or controlled by Modern Healthcare Inc. (“
      <b>Company</b>
      ,” “<b>we</b>
      ,” “<b>our</b>
      ,”or “<b>us</b>
      “), including your use www.joinmodernhealth.com, together with any mobile
      software made available to you by the Company (collectively, the
      <b>“Service”</b>
      ). To access the Service, users must at all times agree to and abide by
      these Terms. The Service allows you to, among other things, submit, store,
      and access certain data and other information related to you and/or your
      business, as applicable (collectively,
      <b>“User Data”</b>
      ).
    </p>
    <p>
      This Terms of Service for the Service is a legal contract between you,
      either an individual subscriber, customer, member, or user of at least 18
      years of age or a single company, organization, or entity (<b>“you”</b>{" "}
      or, collectively with other users,
      <b>“Users”</b>) and Company regarding your use of the Service. Company may
      have different roles with respect to different types of Users, and “you”
      as used in these Terms will apply to the appropriate type of User under
      the circumstances.
    </p>
    <p>
      Please read carefully the following terms of service. By registering for
      and/or accessing, browsing, using or subscribing to the Service, or by
      clicking “I Agree,” or otherwise affirmatively manifesting your intent to
      be bound by these Terms of Service, you signify that you have read,
      understood, and agree to be bound by the following terms, including any
      additional guidelines and any future modifications (collectively, the{" "}
      <b>“Terms”</b>
      ), and to the collection and use of your User Data as set forth in the
      Company Privacy Policy <Link to="/privacy-policy">[link]</Link>.
    </p>
    <p className="readCarefully">
      Please read these Terms carefully to ensure that you understand each
      provision. This Agreement contains a mandatory individual arbitration and
      class action/jury trial waiver provision that requires the use of
      arbitration on an individual basis to resolve disputes, rather than jury
      trials or class actions.
    </p>
    <ol>
      <li>
        <b>Electronic Agreement</b>. This agreement is an electronic contract
        that sets out the legally binding terms of your use of Modern Health’s
        website, products, and services including, without limitation, mobile
        applications developed by Modern Health (together, the “Services”). By
        using the Services, you agree to be bound by these Terms. If you object
        to anything in this Agreement, do not use the Services. You have the
        right to withdraw your consent to have this Agreement provided to you in
        electronic form. See Section 19.9 for information regarding receipt of
        this Agreement in physical form.
      </li>
      <li>
        <b>License Grant</b>. Subject to the terms and conditions of these
        Terms, Company hereby grants to you a limited, personal,
        non-transferable license to use the Service in the manner contemplated
        by these Terms solely for your personal use. Users shall have no right
        to sub-license or resell the Service or any component thereof.
      </li>
      <li>
        <b>No Professional Advice</b>. If the Service provides professional
        information, such information is for informational purposes only and
        should not be construed as professional advice. No action should be
        taken based upon any information contained in the Service. You should
        seek independent professional advice from a person who is licensed
        and/or qualified in the applicable area. Without limiting the foregoing,
        the contents of the Services are for informational purposes only. The
        Company’s products made available through the Service are over the
        counter products that can be purchased without the need for a medical
        prescription. The Services are not intended to be a substitute for
        professional medical advice, diagnosis, or treatment. Always seek the
        advice of your physician or other qualified health provider with any
        questions you may have regarding a medical condition. Never disregard
        professional medical advice or delay in seeking it due to the Services.
        If you think you may have a medical emergency, call your doctor or 911
        immediately. Company does not recommend or endorse any specific tests,
        physicians, prescription products, procedures, opinions, or other
        information in the Services. Reliance on any information provided by
        Company or its employees, agents or third parties via the Services is
        solely at your own risk. The Services may contain health- or
        medical-related materials that are explicit and if you find these
        materials offensive, you may not want to use the Services.
        <p>
          NO LICENSED MEDICAL PROFESSIONAL/PATIENT RELATIONSHIP IS CREATED BY
          USING INFORMATION AND/OR SERVICES PROVIDED BY OR THROUGH THE USE OF
          THE SERVICES OR THROUGH ANY OTHER COMMUNICATIONS FROM US. THE CONTENT
          OF THE SITE AND THE SERVICES ARE NOT AND SHOULD NOT BE CONSIDERED
          MEDICAL ADVICE OR A SUBSTITUTE FOR INDIVIDUAL MEDICAL ADVICE,
          DIAGNOSIS OR TREATMENT. DO NOT DISREGARD, AVOID OR DELAY OBTAINING
          MEDICAL ADVICE FROM A QUALIFIED HEALTH CARE PROFESSIONAL BECAUSE OF
          SOMETHING YOU MAY HAVE READ ON THE SITE. YOU SHOULD ALWAYS TALK TO
          YOUR MEDICAL PROFESSIONALS FOR DIAGNOSIS AND TREATMENT. DO NOT USE THE
          SITE OR SERVICES FOR EMERGENCY MEDICAL NEEDS. IF YOU EXPERIENCE A
          MEDICAL EMERGENCY, CALL 911. YOUR USE OF INFORMATION PROVIDED ON THE
          SITE IS SOLELY AT YOUR OWN RISK. NOTHING STATED OR POSTED ON THE SITE
          OR AVAILABLE THROUGH ANY SERVICES IS INTENDED TO BE, AND MUST NOT BE
          TAKEN TO BE, THE PRACTICE OF MEDICINE OR THE PROVISION OF MEDICAL
          CARE.
        </p>
      </li>
      <li>
        <b>Privacy.</b> User privacy is important to us. Please read our Privacy
        Policy &nbsp;
        <Link to="/privacy-policy">[link]</Link>. &nbsp; carefully for details
        relating to the collection, use, and disclosure of your personal
        information.
      </li>
      <li>
        <b>Modification of the Terms</b>. Company reserves the right, at its
        sole discretion, to change, modify, add, or remove portions of these
        Terms at any time. You agree to review these Terms and any Guidelines
        periodically for changes. When we make minor changes to the Terms we
        will update the ‘last modified’ date at the top of this page. When we
        make changes to the Terms in a material manner, we will update the ‘last
        modified’ date at the top of this page and notify you that material
        changes have been made to these Terms. If any such revision is
        unacceptable to you, your only remedy is to terminate your Company User
        Account.
      </li>
      <li>
        <b>Eligibility; Termination</b>. The Service is not available to persons
        under 18 years of age without parental consent or to any Users
        previously suspended or removed from the Service by Company. Company may
        terminate your account, delete any content or information that you have
        posted on the Service, and/or prohibit you from using or accessing the
        Services (or any portion, aspect, or feature of the Services) for any
        reason or no reason, at any time in its sole discretion, with or without
        notice, including without limitation if it believes that you are under
        18 and parental consent has not been received.
        <p>
          You agree that Company, in its sole discretion and for any or no
          reason, may terminate any account (or any part thereof) you may have
          with Company. In addition, Company reserves the right to discontinue
          any aspect of the Service at any time, including the right to
          discontinue the display of any User Data. You agree that any
          termination of your access to the Service or any account you may have
          or portion thereof may be affected without prior notice, and you agree
          that Company will not be liable to you or any third-party for such
          termination. Any suspected fraudulent, abusive, or illegal activity
          that may be grounds for termination of your use of the Service may be
          referred to appropriate law enforcement authorities. These remedies
          are in addition to any other remedies Company may have at law or in
          equity. Modern Health reserves the right to contact emergency services
          to the extent it, in its sole discretion, believes that a user poses
          an imminent threat of harm to oneself, to property or to another
          person.
        </p>
      </li>
      <li>
        <b>Services</b>. By agreeing to the Terms of this Agreement, users have
        access to Modern Health’s Services. Modern Health’s coaching Services
        may use non-medical unlicensed personnel with the applicable educational
        degrees to provide such coaching; this Service is NOT INTENDED TO, NOR
        DOES IT, PROVIDE CLINICAL COUNSELING OR THERAPY, MEDICAL ADVICE,
        DIAGNOSIS, OR MEDICAL TREATMENT. Users may have access to Teleservices,
        including call-based, video-based, and/or text-based, which may include
        (without limitation) medical referral or emergency call-based Services.
        By agreeing to the Terms of this Agreement, users consent to be
        contacted regarding Company Services and acknowledge the potential risks
        of Teleservices, including, but not limited to technical failures.
      </li>
      <li>
        <b>Account Activity Responsibility</b>.
        <ol>
          <li>
            <u>User Accounts</u>. In order to use certain aspects of the
            Service, you will have to register for the Service and create an
            account (<b>“User Account”</b>
            ). When creating your account for the Service, you agree to provide
            true, accurate, current, and complete information. You further agree
            to maintain and update your personal information as needed to keep
            it true, accurate, current, and complete. You are solely responsible
            for maintaining the confidentiality of your account and password and
            for restricting access to your computer, and you agree to accept
            responsibility for all activities that occur under your account or
            password. If you have reason to believe that your account is no
            longer secure (for example, in the event of a loss, theft or
            unauthorized disclosure or use of your ID, password, or any credit,
            debit or charge card number), you agree to immediately notify
            Company. You may be liable for the losses incurred by Company or
            others due to any unauthorized use of your User Account.
          </li>
          <li>
            <u>System Access</u>. You may choose to allow Company to
            automatically retrieve data from your system(s) or third-party
            systems or services on your behalf. You hereby represent and warrant
            that you have the permission, authority, and rights to allow Company
            to so automatically access such system(s) and services and you
            hereby grant Company permission to access such system(s) and
            services and retrieve User Data therefrom by indicating the same
            within your User Account. Company disclaims any and all liability
            associated with accessing and retrieving User Data from such
            system(s) and services on your behalf. If at any time you do not
            have the right and authority to allow Company automatic access to
            such system(s), then you hereby agree to immediately disable such
            functionality within your User or Administrator Account.
          </li>
          <li>
            <u>Account Information</u>. You acknowledge and agree that Company
            may access, preserve and disclose your account information and
            related contents if required to do so by law or in a good faith
            belief that such access preservation or disclosure is reasonably
            necessary to: (a) comply with legal process; (b) enforce these
            Terms; (c) respond to claims that any User Data violates the rights
            of third parties; (d) respond to your requests for customer service;
            or (e) protect the rights, property or personal safety of Company,
            its users, or the public.
          </li>
        </ol>
      </li>
      <li>
        <b>Restrictions.</b> When using the Service you agree not to:
        <ol>
          <li>
            Upload or transmit via the Service pornographic, threatening,
            embarrassing, hateful, racially or ethnically insulting, libelous,
            or otherwise inappropriate content;
          </li>
          <li>
            Use the Service for any purpose that is unlawful or is otherwise
            prohibited by these Terms;
          </li>
          <li>
            Use the Service in any manner that in our sole discretion could
            damage, disable, overburden, or impair it;
          </li>
          <li>
            Attempt to gain unauthorized access to the Service, or any part of
            them, other User Accounts, computer systems or networks connected to
            the Service, or any part of them, through hacking, password mining
            or any other means or interfere or attempt to interfere with the
            proper working of the Service or any activities conducted on the
            Service;
          </li>
          <li>
            Modify the Service in any manner or form, or use modified versions
            of the Service, including but not limited to for the purpose of
            obtaining unauthorized access to the Service;
          </li>
          <li>
            Use any robot, spider, scraper, or other automated means to access
            the Service for any purpose without our express written permission,
            or bypass any measures we may use to prevent or restrict access to
            the Service;
          </li>
          <li>
            Impersonate another person or access another User’s User Account
            without that User’s permission or to violate any contractual or
            fiduciary relationships;
          </li>
          <li>
            Share Company-issued passwords with any third party or encourage any
            other User to do so;
          </li>
          <li> Misrepresent the source, identity, or content of User Data;</li>
          <li>
            Modify, adapt, translate or create derivative works based upon the
            Service;
          </li>
          <li>
            Reverse engineer, decompile, disassemble or otherwise attempt to
            discover the source code of the Service, except and only to the
            extent that such activity is expressly permitted by applicable law
            notwithstanding this limitation;
          </li>
          <li>
            Rent, lease, loan, resell, sublicense, distribute or otherwise
            transfer the Service to any third party; provide time sharing or
            similar services for any third party; or use the Service for any
            purpose other than your own internal business use;
          </li>
          <li>
            Remove, circumvent, disable, damage or otherwise interfere with
            security-related features of the Service, features that prevent or
            restrict use or copying of any content accessible through the
            Service or Service, or features that enforce limitations on use of
            the Service or Service;
          </li>
          <li>
            Access the Service if you are a direct competitor of Company, except
            with Company’s prior written consent, or for any other competitive
            purposes; or
          </li>
          <li>
            Collect or harvest any personally identifiable information,
            including account names, from the Service.
          </li>
        </ol>
      </li>
      <li>
        <b>User Data</b>.
        <ol>
          <li>
            <u>Use of User Data.</u> By submitting User Data to Company, you
            hereby grant, and represent and warrant that you have all rights
            necessary to grant, all rights and licenses to the User Data
            required for Company and its subcontractors and service providers to
            provide the Service. Company shall have no right to sublicense or
            resell User Data, except however, that you agree that Company may
            collect, analyze, and use data derived from User Data, which may
            include personal data and/or information collected from or about an
            individual but which does not identify the individual personally, as
            well as data about you, and other Users’ access and use of the
            Service, for purposes of operating, analyzing, improving, or
            marketing the Service and any related services. If Company shares or
            publicly discloses information (e.g., in marketing materials, or in
            application development) that is derived from User Data, such data
            will be aggregated or anonymized to reasonably avoid identification
            of a specific individual or the User. By way of example and not
            limitation, Company may: (a) track the number of users on an
            anonymized aggregate basis as part of Company’s marketing efforts to
            publicize the total number of Users of the Service; (b) analyze
            aggregated usage patterns for product development efforts; or (c)
            use anonymous data derived from User Data in a form which may not
            reasonably identify either a particular individual or the User to
            develop further analytic frameworks and application tools. You
            further agree that Company will have the right, both during and
            after the term of these Terms, to use, store, transmit, distribute,
            modify, copy, display, sublicense, and create derivative works of
            the anonymized, aggregated data. See Modern Health’s Privacy Policy{" "}
            <Link to="/privacy-policy">[link]</Link>.
          </li>
          <li>
            <u>Your Responsibilities for User Data</u>. In connection with User
            Data, you hereby represent, warrant, and agree that: (a) you have
            obtained the User Data lawfully, and the User Data does not and will
            not violate any applicable laws or any person or entity’s
            proprietary or intellectual property rights; (b) the User Data is
            free of all viruses, Trojan horses, and other elements that could
            interrupt or harm the systems or software used by Company or its
            subcontractors to provide the Service; (c) all User Data has and
            will be collected by you in accordance with a privacy policy that
            permits Company to share, collect, use, and disclose such User Data
            as contemplated under these Terms, and if required by applicable
            law, pursuant to consents obtained by you to do each of the
            foregoing; (d) you are solely responsible for ensuring compliance
            with all privacy laws in all jurisdictions that may apply to User
            Data provided hereunder; (e) Company may exercise the rights in User
            Data granted hereunder without liability or cost to any third party;
            and (f) the User Data complies with the terms of these Terms. For
            purposes of clarity, Company takes no responsibility and assumes no
            liability for any User Data, and you will be solely responsible for
            its User Data and the consequences of sharing it hereunder.
          </li>
          <li>
            <u>Security Incidents</u>. In the event that User Data is disclosed
            to or accessed by an unauthorized party, Company will promptly
            notify you and use reasonable efforts to cooperate with your
            investigation of the incident.
          </li>
          <li>
            <u>No Responsibility for Backups</u>. Company will not be
            responsible for any backup, recovery or other steps required to
            ensure that User Data is recoverable in the case of data loss. You
            are solely responsible for backing up your User Data on a regular
            basis, and taking appropriate steps to safeguard and ensure the
            integrity of your User Data.
          </li>
          <li>
            <u>Rights to User Data</u>. You own all right, title and interest
            (including all intellectual property rights) in and to your User
            Data.
          </li>
        </ol>
      </li>
      <li>
        <b>Ownership; Proprietary Rights</b>.
        <ol>
          <li>
            The Service is owned and operated by Company. The visual interfaces,
            graphics, design, compilation, information, computer code, products,
            software, services, and all other elements of the Service provided
            by Company, but expressly excluding User Content (defined below) and
            User Data (<b>Materials</b>) are protected by intellectual property
            and other applicable laws. Except for any technology licensed by
            Company, which is owned by and provided by our third-party
            licensors, all Materials contained in the Service, including without
            limitation the intellectual property rights therein and thereto, are
            the property of Company or its subsidiaries or affiliated companies.
            All trademarks, service marks, and trade names are proprietary to
            Company or its affiliates and/or third-party licensors. Except as
            expressly provided herein, nothing in these Terms shall be deemed to
            create a license in or under any such Materials or the intellectual
            property rights therein or thereto, you agree not to sell, license,
            distribute, copy, modify, publicly perform or display, transmit,
            publish, edit, adapt, create derivative works from, or otherwise
            make unauthorized use of the Materials
          </li>
          <li>
            We may use User Content in a number of different ways, including by
            publicly displaying it, reformatting it, incorporating it into
            advertisements and other works, creating derivative works from it,
            promoting it, and distributing it. <b>“User Content”</b> means any
            text, images, photos, audio, video, location data, and all other
            forms of data or communication, excluding User Data, that you submit
            or transmit to, through, or in connection with Service that you
            publicly display or is displayed in your account profile. As such,
            you confirm and warrant that you own or control all rights to your
            User Content and hereby irrevocably grant us world-wide, perpetual,
            non-exclusive, royalty-free, assignable, sublicensable, transferable
            rights to use your User Content for any purpose. Finally, you
            irrevocably waive, and cause to be waived, against Company and its
            users any claims and assertions of moral right or attribution with
            respect to your User Content. By <b>“use”</b> we mean use, copy,
            publicly perform and display, reproduce, distribute, modify,
            translate, remove, analyze, commercialize, and prepare derivative
            works of your User Content. We are not and shall not be under any
            obligation, except as otherwise expressly set forth in these Terms
            or our other policies, (1) to maintain your User Content in
            confidence; (2) to pay you any compensation for any User Content;
            (3) to credit or acknowledge you for your USer Content; or (4) to
            respond to your User Content.
          </li>
          <li>
            You may choose to or we may invite you to submit comments or ideas
            about the Service, including without limitation about how to improve
            the Service or our products (<b>“Ideas”</b>
            ). By submitting any Idea, you agree that your disclosure is
            gratuitous, unsolicited and without restriction and will not place
            Company under any fiduciary or other obligation, and that we are
            free to use the Idea without any additional compensation to you,
            and/or to disclose the Idea on a non-confidential basis or otherwise
            to anyone. You further acknowledge that, by acceptance of your
            submission, Company does not waive any rights to use similar or
            related ideas previously known to Company, or developed by its
            employees, or obtained from sources other than you.
          </li>
        </ol>
      </li>
      <li>
        <b>Third-Party Sites, Third-Party Information</b>.
        <ol>
          <li>
            The Service may call the servers of other websites or services
            solely at the direction of and as a convenience to Users (
            <b>“Third Party Sites”</b>
            ). Company makes no express or implied warranties with regard to the
            information, or other material, products, or services that are
            contained on or accessible through Third-Party Sites. Access and use
            of Third Party Sites, including the information, material, products,
            and services on such sites or available through such sites, is
            solely at your own risk.
          </li>
          <li className="italic">
            You acknowledge that Company does not manage or control the User
            Data and/or User Content that you access, store or distribute
            through the Service, and accepts no responsibility or liability for
            that information regardless of whether such User Data and/or User
            Content is transmitted to or by you in breach of these Terms.
            Company makes no warranty with respect to such User Data and/or User
            Content you may access, store or distribute through the Service. In
            particular, without limiting the generality of the foregoing,
            Company makes no warranty that such User Data and/or User Content
            will be free of any virus, worm, trojan horse, easter egg, time
            bomb, cancelbot, or other destructive or malicious code or programs.
            You agree to waive, and hereby do waive, any legal or equitable
            rights or remedies you have or may have against Company with respect
            to third party and/or User Data and/or User Content that you choose
            to access, store or distribute, through the Service.
          </li>
        </ol>
      </li>
      <li>
        <b>Security and Privacy Settings</b>. We have implemented commercially
        reasonable technical and organizational measures designed to secure your
        User Data from accidental loss and from unauthorized access, use,
        alteration or disclosure. However, we cannot guarantee that unauthorized
        third parties will never be able to defeat those measures or use your
        User Data for improper purposes. You understand that internet
        technologies have the inherent potential for disclosure. You acknowledge
        that you are under no obligation to provide personal data or other
        sensitive information in order to use the Service and that you provide
        any such information at your own risk.
      </li>
      <li>
        <b>Disclaimers; No Warranties</b>.
        <ol>
          <li className="italic">
            The Service and any third-party or User Data, software, services, or
            applications made available in conjunction with or through the
            Service is provided “as is” and “as available” without warranties of
            any kind either express or implied. To the fullest extent
            permissible pursuant to applicable law, Company, its suppliers,
            licensors, and partners disclaim all warranties, statutory, express
            or implied, including, but not limited to, implied warranties of
            merchantability, fitness for a particular purpose, and
            non-infringement of proprietary rights.
          </li>
          <li className="italic">
            Company, its suppliers, licensors, and partners do not warrant that
            the functions contained in the Service will be uninterrupted or
            error-free, that the Service will meet your requirements, that
            defects will be corrected, or that the Service or the server that
            makes it available is free of viruses or other harmful components.
          </li>
          <li className="italic">
            Company, its suppliers, licensors, and partners do not warrant or
            make any representations regarding the use or the results of the use
            of the Service in terms of correctness, accuracy, reliability, or
            otherwise. You understand and agree that you download or otherwise
            obtain third party or User Data, material, or data through the use
            of the Service at your own discretion and risk and that you will be
            solely responsible for any damage to your computer system or loss of
            data that results from the download of such third party or User
            provided information, material, or data. Company will not be
            responsible or liable for the deletion, correction, destruction,
            damage, loss, or failure to store or maintain any third-party or
            User Data.
          </li>
          <li className="italic">
            Certain state laws do not allow limitations on implied warranties or
            the exclusion or limitation of certain damages. If these laws apply
            to you, some or all of the above disclaimers, exclusions, or
            limitations may not apply to you, and you might have additional
            rights.
          </li>
        </ol>
      </li>
      <li>
        <b>Limitation of Liability</b>.
        <ol>
          <li className="italic">
            Under no circumstances, including, but not limited to, negligence,
            will Company or its affiliates, contractors, employees, agents, or
            third-party partners, licensors, or suppliers be liable for any
            special, indirect, incidental, consequential, punitive, reliance, or
            exemplary damages (including without limitation losses or liability
            resulting from loss of data, loss of revenue, anticipated profits,
            or loss of business opportunity) that result from your use or your
            inability to use the information or materials on the Service, or any
            other interactions with Company, even if Company or a Company
            authorized representative has been advised of the possibility of
            such damages. Applicable law may not allow the limitation or
            exclusion of liability for incidental or consequential damages, so
            the above limitation or exclusion may not apply to you. In such
            cases, Company’s liability will be limited to the fullest extent
            permitted by applicable law.
          </li>
          <li className="italic">
            In no event will Company’s or its affiliates’, contractors’,
            employees’, agents’, or third-party partners’, licensor’s, or
            suppliers’ total liability to you for all damages, losses, and
            causes of action arising out of or relating to these terms or your
            use of the Service, including without limitation your interactions
            with other users, (whether in contract, tort including negligence,
            warranty, or otherwise) exceed the amount paid by you, if any, for
            accessing the Service during the twelve (12) months immediately
            preceding the day the act or omission occurred that gave rise to
            your claim or one hundred dollars, whichever is greater.
          </li>
          <li className="italic">
            You acknowledge and agree that Company has offered its products and
            services, set its prices, and entered into these terms in reliance
            upon the disclaimers of warranty and the limitations of liability
            set forth herein, that the disclaimers of warranty and the
            limitations of liability set forth herein reflect a reasonable and
            fair allocation of risk between the parties (including the risk that
            a contract remedy may fail of its essential purpose and cause
            consequential loss), and that the disclaimers of warranty and the
            limitations of liability set forth herein form an essential basis of
            the bargain between you and Company.
          </li>
        </ol>
      </li>
      <li>
        <b>Indemnification</b>. You agree to defend, indemnify and hold harmless
        Company and its subsidiaries, agents, managers, and other affiliated
        companies, and their employees, contractors, agents, officers and
        directors, from and against any and all claims, damages, obligations,
        losses, liabilities, costs or debt, and expenses (including but not
        limited to attorney
        {"'"}s fees) arising from: (a) your use of and access to the Service,
        including any data or work transmitted or received by you; (b) your
        violation of any term of these Terms, including without limitation, your
        breach of any of the representations and warranties above; (c) your
        violation of any third-party right, including without limitation any
        right of privacy, publicity rights or intellectual property rights; (d)
        your violation of any law, rule or regulation of the United States or
        any other country; (e) any claim or damages that arise as a result of
        any of your User Data, User Content, or any other content or data that
        are submitted via your account; or (f) any other party’s access and use
        of the Service with your unique username, password or other appropriate
        security code. Company will have the right to control the defense,
        settlement, adjustment or compromise of any such claims, actions or
        proceedings by using counsel selected by Company. Company will use
        reasonable efforts to notify you of any such claims, actions, or
        proceedings upon becoming aware of the same.
      </li>
      <li>
        <b>Location of the Service</b>. The Service is controlled and operated
        from our facilities in the United States. Company makes no
        representations that the Service is appropriate or available for use in
        other locations. Those who access or use the Service from other
        jurisdictions do so at their own volition and are entirely responsible
        for compliance with local law, including but not limited to export and
        import regulations. You may not use the Service if you are a resident of
        a country embargoed by the United States, or are a foreign person or
        entity blocked or denied by the United States government. Unless
        otherwise explicitly stated, all materials found on the Service are
        solely directed to individuals, companies, or other entities located in
        the U.S. By using the Service, you are consenting to have your personal
        data transferred to and processed in the United States.
      </li>
      <li>
        <b>Governing Law; Arbitration; and Class Action/Jury Trial Waiver</b>.
        <ol>
          <li>
            <u>Governing Law</u>. You agree that: (a) the Service shall be
            deemed solely based in California; and (b) the Service shall be
            deemed a passive one that does not give rise to personal
            jurisdiction over us, either specific or general, in jurisdictions
            other than California. This Agreement shall be governed by the
            internal substantive laws of the State of California, without
            respect to its conflict of laws principles. The parties acknowledge
            that these Terms evidences a transaction involving interstate
            commerce. Notwithstanding the preceding sentences with respect to
            the substantive law, any arbitration conducted pursuant to the terms
            of these Terms shall be governed by the Federal Arbitration Act (9
            U.S.C. §§ 1-16). The application of the United Nations Convention on
            Contracts for the International Sale of Goods is expressly excluded.
            You agree to submit to the personal jurisdiction of the federal and
            state courts located in Santa Clara County, California for any
            actions for which we retain the right to seek injunctive or other
            equitable relief in a court of competent jurisdiction to prevent the
            actual or threatened infringement, misappropriation or violation of
            a our copyrights, trademarks, trade secrets, patents, or other
            intellectual property or proprietary rights, as set forth in the
            Arbitration provision below, including any provisional relief
            required to prevent irreparable harm. You agree that Santa Clara
            County, California is the proper forum for any appeals of an
            arbitration award or for trial court proceedings if the arbitration
            provision below is found to be unenforceable.
          </li>
          <li>
            <u>Arbitration</u>.
            <i>
              Read this section carefully because it requires the parties to
              arbitrate their disputes and limits the manner in which you can
              seek relief from Company.
            </i>{" "}
            For any dispute with Company, you agree to first contact us at
            <a href="mailto:info@joinmodernhealth.com">
              {" "}
              info@joinmodernhealth.com{" "}
            </a>
            and attempt to resolve the dispute with us informally. In the
            unlikely event that Company has not been able to resolve a dispute
            it has with you after sixty (60) days, we each agree to resolve any
            claim, dispute, or controversy (excluding any claims for injunctive
            or other equitable relief as provided below) arising out of or in
            connection with or relating to these Terms, or the breach or alleged
            breach thereof (collectively, “Claims”), by binding arbitration by
            JAMS, under the Optional Expedited Arbitration Procedures then in
            effect for JAMS, except as provided herein. JAMS may be contacted at{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.jamsadr.com"
            >
              www.jamsadr.com
            </a>
            . The arbitration will be conducted in Santa Clara County,
            California, unless you and Company agree otherwise. If you are using
            the Service for commercial purposes, each party will be responsible
            for paying any JAMS filing, administrative and arbitrator fees in
            accordance with JAMS rules, and the award rendered by the arbitrator
            shall include costs of arbitration, reasonable attorneys’ fees and
            reasonable costs for expert and other witnesses. Any judgment on the
            award rendered by the arbitrator may be entered in any court of
            competent jurisdiction. Nothing in this Section shall be deemed as
            preventing Company from seeking injunctive or other equitable relief
            from the courts as necessary to prevent the actual or threatened
            infringement, misappropriation, or violation of our data security,
            intellectual property or other proprietary rights.
          </li>
          <li>
            <u>Class Action/Jury Trial Waiver</u>.
            <i>
              {" "}
              With respect to all persons and entities, regardless of whether
              they have obtained or used the Service for personal, commercial or
              other purposes, all claims must be brought in the parties’
              individual capacity, and not as a plaintiff or class member in any
              purported class action, collective action, private attorney
              general action or other representative proceeding. This waiver
              applies to class arbitration, and, unless we agree otherwise, the
              arbitrator may not consolidate more than one person’s claims. You
              agree that, by entering into these Terms, you and Company are each
              waiving the right to a trial by jury or to participate in a class
              action, collective action, private attorney general action, or
              other representative proceeding of any kind.
            </i>
          </li>
        </ol>
      </li>
      <li>
        <b>Miscellaneous</b>.
        <ol>
          <li>
            <u>Notice and Modifications.</u> Company may provide you with
            notices, including those regarding changes to Company’s terms and
            conditions, by email, regular mail, or postings on the Service.
            Notice will be deemed given twenty-four hours after email is sent,
            unless Company is notified that the email address is invalid.
            Alternatively, we may give you legal notice by mail to a postal
            address, if provided by you through the Service. In such case,
            notice will be deemed given three days after the date of mailing.
            Notice posted on the Service is deemed given five (5) days following
            the initial posting. Company reserves the right to determine the
            form and means of providing notifications to our Users, provided
            that you may opt out of certain means of notification as described
            in these Terms. Company is not responsible for any automatic
            filtering you or your network provider may apply to email
            notifications we send to the email address you provide us. Company
            may, in its sole discretion, modify or update these Terms from time
            to time, and so you should review this page periodically. When we
            change the Agreement in a material manner, we will update the ‘last
            modified’ date at the bottom of this page and notify you that
            material changes have been made to the Agreement. Your continued use
            of the Service after any such change constitutes your acceptance of
            the new Terms of Service. If you do not agree to any of these terms
            or any future Terms of Service, do not use or access (or continue to
            access) the Service.
          </li>
          <li>
            <u>Waiver</u>. The failure of Company to exercise or enforce any
            right or provision of these Terms will not constitute a waiver of
            such right or provision. Any waiver of any provision of these Terms
            will be effective only if in writing and signed by Company.
          </li>
          <li>
            <u>Severability</u>. If any provision of these Terms, an Individual
            Service Agreement, or any Guideline is held to be unlawful, void, or
            for any reason unenforceable, then that provision will be limited or
            eliminated from these Terms to the minimum extent necessary and will
            not affect the validity and enforceability of any remaining
            provisions; except that in the event of unenforceability of the
            universal Class Action/Jury Trial Waiver, the entire arbitration
            agreement shall be unenforceable.
          </li>
          <li>
            <u>Assignment</u>. These Terms, Individual Service Agreements, and
            related Guidelines, and any rights and licenses granted hereunder,
            may not be transferred or assigned by you, but may be assigned by
            Company without restriction.
          </li>
          <li>
            <u>Survival</u>. Upon termination of these Terms, any provision
            which, by its nature or express terms should survive, shall survive
            such termination or expiration, including, but not limited to,
            Sections 3 and 8 through 21.
          </li>
          <li>
            <u>Headings</u>. The heading references herein are for convenience
            only, do not constitute a part of these Terms, and will not be
            deemed to limit or affect any of the provisions hereof.
          </li>
          <li>
            <u>Entire Agreement</u>. This, including the agreements incorporated
            by reference, constitutes the entire agreement between you and
            Company relating to the subject matter herein and will not be
            modified except in writing, signed by both parties, or by a change
            made by Company as set forth in these Terms.
          </li>
          <li>
            <u>Claims</u>. You agree that any cause of action arising out of or
            related to the Service must commence within one (1) year after the
            cause of action accrues. Otherwise, such cause of action is
            permanently barred.
          </li>
          <li>
            <u>Disclosures</u>. The Service is offered by Modern Healthcare,
            Inc., located at 576 Sacramento Street, San Francisco, CA, and can
            be reached via email at{" "}
            <a href="mailto:help@joinmodernhealth.com">
              {" "}
              help@joinmodernhealth.com{" "}
            </a>{" "}
            or telephone at 617-980-9633. If you are a California resident, (a)
            you may have this same information mailed to you by emailing the
            foregoing email address with your address and a request for this
            information; and (b) in accordance with Cal. Civ. Code §1789.3, you
            may report complaints to the Complaint Assistance Unit of the
            Division of Consumer Services of the California Department of
            Consumer Affairs by contacting them in writing at 1625 North Market
            Blvd., Suite N 112 Sacramento, CA 95834, or by telephone at (800)
            952-5210 or (916) 445-1254.
          </li>
        </ol>
      </li>
    </ol>
  </SectionWrapper>
);
